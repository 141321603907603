import { IDeclaration, IDeclarationPersistence, IDeclarationExcel } from '../interfaces';
import { getCountryName } from '@infra/utils';
export class DeclarationMapper {
  public static toDomain(declaration: IDeclarationPersistence): IDeclaration {
    return {
      id: declaration.id,
      awb: declaration.awb,
      globalTrackCode: declaration.global_track_code,
      trackCode: declaration.track_code,
      requiresDeclaration: declaration.requires_declaration,
      weight: declaration.weight ? parseFloat(declaration.weight) : null,
      height: declaration.height ? parseFloat(declaration.height) : null,
      width: declaration.width ? parseFloat(declaration.width) : null,
      depth: declaration.depth ? parseFloat(declaration.depth) : null,
      price: declaration.price ? parseFloat(declaration.price) : null,
      canAccommodate: declaration.can_accommodate,
      voen: declaration.voen,
      couponId: declaration.coupon_id,
      deliveryPrice: declaration.delivery_price ? parseFloat(declaration.delivery_price) : null,
      parcel: declaration.box ? { id: declaration.box } : null,
      parcelSorting: { id: declaration.parcel_sorting_id, state_name: declaration.parcel_sorting_state_name, created_at: declaration.parcel_sorting_created_at },
      basket: declaration.basket_id ? { id: declaration.basket_id, name: declaration.basket_name || '' } : null,
      box: declaration.container_id && declaration.container_name ? { id: declaration.container_id, name: declaration.container_name } : null,
      lastBox: declaration.container_id_tmp && declaration.container_name_tmp ? { id: declaration.container_id_tmp, name: declaration.container_name_tmp } : null,
      branch: declaration.branch_id ? { id: declaration.branch_id, name: declaration.branch_name || '' } : null,
      quantity: declaration.quantity,
      type: declaration.type === 1 ? 'liquid' : 'other',
      shop: declaration.shop_name,
      file: declaration.document_file,
      planCategory: { id: declaration.tariff_category_id, name: declaration.tariff_category_name },
      status: { id: declaration.state_id, name: declaration.state_name },
      productType: { id: declaration.product_type_id, name: declaration.product_type_name },
      user: { id: declaration.user_id, name: declaration.user_name },
      description: declaration.descr || '',
      read: !!declaration.is_new,
      createdAt: declaration.created_at,
      isCommercial: !!declaration.is_commercial,
      paid: !!declaration.payed,
      approved: !!declaration.customs,
      returned: !!declaration.return,
      document: declaration.document_file,
      flight: declaration.flight_name ? { id: 0, name: declaration.flight_name } : null,
      countryId: declaration.country_id,
      wardrobeNumber: declaration.wardrobe_number,
      isYourBranch: declaration.is_your_branch ? declaration.is_your_branch : false,
      customs: declaration.customs,
      locationName: declaration.branch_name ? declaration.branch_name : '',
      locationId: declaration.branch_id ? declaration.branch_id : 0,
      causerId: declaration.causer_id || 0,
      deliveryPoint: declaration.delivery_point_id
        ? {
            id: declaration.delivery_point_id,
            name: declaration.delivery_point_name ? declaration.delivery_point_name : '',
          }
        : null,
      trendyol: declaration.trendyol,
      trendyolLogs: !!declaration.trendyol_logs
        ? {
            invoice: {
              invoicePrice: declaration.trendyol_logs?.invoice.invoice_price || 0,
              invoiceUrl: declaration.trendyol_logs?.invoice.invoice_url || '',
            },
            products: {
              sku: declaration.trendyol_logs?.products.sku || '',
            },
            customsStatus: declaration.trendyol_logs?.customs_status || '',
            zipCode: declaration.trendyol_logs?.zip_code || '',
            phoneNumber: declaration.trendyol_logs?.phone_number || '',
            weight: declaration.trendyol_logs?.weight || '',
            smsCount: declaration.trendyol_logs?.sms_count || 0,
            smsDate: declaration.trendyol_logs?.sms_date || '',
            state: declaration.trendyol_logs?.state || '',
            trendyolDeliveryNumber: declaration.trendyol_logs?.trendyol_delivery_number || '',
            warehouseId: declaration.trendyol_logs?.warehouse_id || '',
            type: declaration.trendyol_logs?.type || '',
            unitPrice: declaration.trendyol_logs?.unit_price || '',
            volume: declaration.trendyol_logs?.volume || 0,
            name: declaration.trendyol_logs?.name || '',
            parcelId: declaration.trendyol_logs?.parcel_id || '',
            pinCode: declaration.trendyol_logs?.pin_code || '',
            quantity: declaration.trendyol_logs?.quantity || 0,
            shippingAddress: declaration.trendyol_logs?.shipping_address || '',
            category: declaration.trendyol_logs?.category || '',
            city: declaration.trendyol_logs?.city || '',
            comment: declaration.trendyol_logs?.comment || '',
            createdAt: declaration.trendyol_logs?.created_at || '',
            declarationId: declaration.trendyol_logs?.declaration_id || '',
            domesticCargoCompany: declaration.trendyol_logs?.domestic_cargo_company || '',
            emailAddress: declaration.trendyol_logs?.email_address || '',
            fullName: declaration.trendyol_logs?.full_name || '',
            isDoor: declaration.trendyol_logs?.is_door === 1,
            isDeclared: declaration.trendyol_logs?.is_declared === 1,
            isLiquid: declaration.trendyol_logs?.is_liquid === 1,
            isMicro: declaration.trendyol_logs?.is_micro === 1,
            uid: declaration.trendyol_logs?.uid?.join(', ') || '',
          }
        : null,
      editable: declaration.editable === 1,
      bbs: {
        user: declaration.who_added_bbs,
        date: declaration.when_added_bbs,
      },
    };
  }
  public static toExcel(declaration: IDeclarationPersistence): IDeclarationExcel {
    return {
      'M.kodu': declaration.user_id,
      Musteri: declaration.user_name,
      Olke: getCountryName(declaration.country_id),
      'Izleme kodu': declaration.track_code,
      'Q.I kodu': declaration.global_track_code,
      Filial: declaration.branch_name?.replaceAll('"', '') || '',
      Yesik: declaration.container_name,
      Yaradilib: declaration.created_at,
      Beyan: !!declaration.customs,
      'Catdirilma qiymeti': declaration.delivery_price ? parseFloat(declaration.delivery_price) : null,
      'Mehsulun qiymeti': declaration.price ? parseFloat(declaration.price) : null,
      Ucus: declaration.flight_name,
      Koli: declaration.box || '',
      Odenis: !!declaration.payed,
      'Mehsulun tipi': declaration.product_type_name,
      Say: declaration.quantity,
      Iade: !!declaration.return,
      Magaza: declaration.shop_name,
      Status: declaration.state_name,
      Tarif: declaration.tariff_category_name,
      Terkib: declaration.type === 1 ? 'liquid' : 'other',
      Ceki: declaration.weight ? parseFloat(declaration.weight) : null,
    };
  }
}
